<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <div style="display: flex; justify-content: space-between">
                  <h6 class="mb-0">Danh sách mốc nhận quà</h6>
                  <div>
                    <router-link :to="'/campaign/mileStone/create'">
                      <b-button class="btn btn-light font-weight-bold btn-sm">
                        Tạo mới
                      </b-button>
                    </router-link>
                  </div>
                </div>
                <b-form @submit.prevent="search()">
                  <div class="filter-transaction">
                    <div class="row mb-6">
                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <label>Tên bảng xếp hạng:</label>
                        <input type="text" placeholder="Nhập mã trường" class="form-control datatable-input"
                          v-model="paramFilter.code" />
                      </div>

                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <label>Trạng thái</label>
                        <select class="form-control datatable-input" data-col-index="7" v-model="paramFilter.status">
                          <option value="">Tất cả</option>
                          <option value="1">Kích hoạt</option>
                          <option value="0">Không kích hoạt</option>
                        </select>
                      </div>

                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <b-form-group id="input-group-4" label="Ngày bắt đầu:" label-for="dp1" class="col-lg-12">
                          <date-picker id="dp1" name="dp1" format="YYYY-MM-DD " value-type="format"
                            v-model="paramFilter.from_date" type="date" placeholder="Chọn thời gian"></date-picker>
                        </b-form-group>
                      </div>

                      <div class="col-lg-3 mb-lg-0 mb-6">
                        <b-form-group id="input-group-4" label="Ngày kết thúc:" label-for="dp1" class="col-lg-12">
                          <date-picker id="dp1" name="dp1" format="YYYY-MM-DD " value-type="format"
                            v-model="paramFilter.to_date" type="date" placeholder="Chọn thời gian"></date-picker>
                        </b-form-group>
                      </div>
                      
                      <button class="btn btn-primary btn-primary--icon">
                          <span>
                            <i class="la la-search"></i>
                            <span>Tìm kiếm</span>
                          </span>
                        </button>
                    </div>
                  </div>
                </b-form>
              </template>

              <b-table table-class="datatable-table" hover tbody-tr-class="datatable-row" thead-tr-class="datatable-row"
                thead-class="datatable-head" details-td-class="datatable-cell" :items="items" :fields="fields">
                <template #cell(stt)="data">
                  <div>
                    {{ data.index + 1 }}
                  </div>
                </template>

                <template #cell(title)="data">
                  <div>
                    {{ data.item.title }}
                  </div>
                </template>

                <template #cell(banner)="data">
                  <div>
                    <img width="50" :src="data.item.banner">
                  </div>
                </template>
                
                <template #cell(mileStone)="data">
                    <div v-for="(value, index) in data.item.mile_stone" :key="index">
                      <span><b>{{ value.title ? value.title : 'N/A' }} :
                        </b></span><span>{{ value.num ? value.num : 'N/A' }} lượt</span> 
                    </div>
                </template>

                <template #cell(status)="data">
                  <div class="label label-inline label-light-success" v-if="data.item.status == 1">
                    ON
                  </div>
                  <div class="label label-inline label-light-danger" v-else>
                    OFF
                  </div>
                </template>

                <template #cell(date_start)="data">
                  <div>
                    {{ formatDate(data.item.date_start) }}
                  </div>
                </template>

                <template #cell(date_end)="data">
                  <div>
                    {{ formatDate(data.item.date_end) }}
                  </div>
                </template>

                <template #cell(action)="data">
                  <div style="display: flex">
                    <router-link :to="'/campaign/mileStone/edit/' + data.item.id">
                      <b-button style="margin-right: 10px;" pill variant="info" size="sm">Sửa </b-button>
                    </router-link>

                    <b-button @click="destroy(data.item.id)" pill variant="danger" size="sm">Xóa
                    </b-button>

                    <!-- <b-button @click="viewRankDetail(data.item.id)" pill variant="success" size="sm">Xem chi tiết thông tin
                    </b-button> -->
                  </div>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination v-model="currentPage" :link-gen="createLinkPage" :number-of-pages="paginate.totalPage"
                  :total-rows="paginate.total" :per-page="paginate.limit" use-router first-number last-number
                  align="right"></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const CampaignRepository = RepositoryFactory.get("Campaign");
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  name: "List.vue",
  mixins: [Common],
  components: {
    DatePicker
  },
  data() {
    return {
      fields: [
        {
          key: "stt",
          label: "STT",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "title",
          label: "Tên BXH",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "banner",
          label: "Banner",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "mileStone",
          label: "Thông tin mốc",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_start",
          label: "Ngày bắt đầu",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "date_end",
          label: "Ngày kết thúc",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "action",
          label: "Hành động",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      items: [],
      currentPage: 1,
      paramFilter: {
        code: '',
        name: '',
        domain: '',
        from_date: '',
        to_date: '',
        status: ''
      },
      arrayFilter: [],
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      clients: [],
      mileStone: []
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lý BXH", route: "/campaign/mileStone" },
    ]);
    this.filter = this.$route.query;
  },
  methods: {
    formatDate(value) {
      return moment(String(value)).format("YYYY-MM-DD hh:mm:ss");
    },
    async search() {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.getList();
    },
    async getList(hide = false) {
      try {
        if (!hide) {
          this.$bus.$emit("show-loading", true);
        }
        this.paramFilter.page = this.currentPage;
        let response = await CampaignRepository.getListChallengeRank(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data;     
        if (body.data) {
          let list = body.data;
          this.items = list.data;
          this.items.forEach((item) => {
            this.mileStone.push(item.mile_stone);
          });
          let dataTest = [];
          this.mileStone.forEach((value, index) => {
            dataTest.push(value[index]);
          });
          this.arrayFilter = body.data.array_filter;
          this.paginate.total = list.total;
          this.paginate.totalPage = list.last_page;
        }
      } catch (e) {
        this.$bus.$emit("show-loading", false);
      }
    },

    async destroy(id) {
      if (confirm("Bạn có chắc xóa bảng xếp hạng này không?")) {
        try {
          this.$bus.$emit("show-loading", true);
          let response = await CampaignRepository.deleteChallengeRank(id);
          this.$bus.$emit("show-loading", false);
          if (1 === response.data.error_code) {
            this.$notify({
              group: "foo",
              type: "error",
              title: response.data.message,
            });
          } else {
            this.$notify({
              group: "foo",
              type: "success",
              title: response.data.message,
            });
            this.getList(true);
          }
        } catch (e) {
          this.$bus.$emit("show-loading", false);
        }
      }
    },
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    // viewRankDetail(data){
    //   console.log(data);
    // }
    
  },
  watch: {
    $route: "getList",
    currentPage() {
      this.getList();
    },
  },
  created() {
    this.getClients();
    this.getList();
  }
};
</script>

<style >
.mx-datepicker {
  width: 100%;
}
</style>
